<template>
<v-app-bar
        height="50"
        color="teal-darken-1"
      >
        <template v-slot:image>
          <v-img
            gradient="to top right, rgba(0, 255, 204,.8), rgba(2, 48, 71, 1)"
          ></v-img>
        </template>

        <v-btn variant="tonal" @click="logout">
          <v-icon
          start
          icon="mdi-home"
          ></v-icon>
          HOME
        </v-btn>

        <!-- <template v-slot:prepend>
          <v-app-bar-nav-icon @click="toggleState"></v-app-bar-nav-icon>
        </template> -->

        <!-- <v-app-bar-title>แพทย์ไทยนอนหลับ</v-app-bar-title> -->
</v-app-bar>

    <v-container fluid>
	<v-row align="center" justify="center" >
    <v-card flat
        class="mx-auto"
        width=auto
        height="auto">


    <v-card-item v-if="mainCard === true">
    <div class="quiz-container">
      <h1>ผลลัพธ์การวิเคราะห์</h1>
      <div class="result-icon">
        <div class="emoji_">{{ setState_Mood }}</div>
      </div>
      <p class="congrats-message">{{ setStateStatusSleep }}</p>
      <div class="results">
        <div class="result-box score-box">
          <h5>คุณภาพการนอน</h5>
          <p class="result">{{ parseInt(percentageSleep) }}%</p>
          <!-- <p class="passing">คะแนนวิเคราะห์: {{ passingScore }}</p> -->
        </div>
        <div class="result-box points-box">
          <h5>คะแนนการนอน</h5>
          <p class="result">{{ analysisScore }}</p>
          <!-- <p class="passing">คะแนนวิเคราะห์: {{ passingPoints }}</p> -->
        </div>
      </div>

      <v-dialog max-width="auto" max-height="700">
      <template v-slot:activator="{ props: activatorProps }" >
      <v-btn size="x-large"  class="buttonEvaluate"   v-bind="activatorProps">ประเมินความพึงพอใจ</v-btn>
     </template>


     <template v-slot:default="{ isActive }" >
    <v-card  >
      <v-toolbar title="ให้คะแนนความพึงพอใจ" align="center" justify="center"></v-toolbar>

        <v-card-actions class="md-4">
          <div >
          <div class="text-subtitle-2"><b>{{ rating.r1.q1 }}</b></div>
          <v-rating
            active-color="amber-accent-4"
            color="blue-grey-darken-4"
            v-model="rating.r1.a1"
            :item-labels="['แย่', 'พอใช้', 'ปานกลาง', 'ดี', 'ดีมาก']"
            class="mt-1 md-2"
            item-label-position="top"
          >
          <template v-slot:item-label="props">
          <span
            :class="`text-${rcolors[props.index]}`"
            class="font-weight-black text-caption"
          >
            {{ props.label }}
          </span>
        </template>
        </v-rating>

        <div class="text-subtitle-2"><b>{{ rating.r2.q2 }}</b></div>
          <v-rating
            active-color="amber-accent-4"
            color="blue-grey-darken-4"
            v-model="rating.r2.a2"
            :item-labels="['แย่', 'พอใช้', 'ปานกลาง', 'ดี', 'ดีมาก']"
            class="mt-1 md-2"
            item-label-position="top"
          >
          <template v-slot:item-label="props">
          <span
            :class="`text-${rcolors[props.index]}`"
            class="font-weight-black text-caption"
          >
            {{ props.label }}
          </span>
        </template>
        </v-rating>

        <div class="text-subtitle-2"><b>{{ rating.r3.q3 }}</b></div>
          <v-rating
            active-color="amber-accent-4"
            color="blue-grey-darken-4"
            v-model="rating.r3.a3"
            :item-labels="['แย่', 'พอใช้', 'ปานกลาง', 'ดี', 'ดีมาก']"
            class="mt-1 md-2"
            item-label-position="top"
          >
          <template v-slot:item-label="props">
          <span
            :class="`text-${rcolors[props.index]}`"
            class="font-weight-black text-caption"
          >
            {{ props.label }}
          </span>
        </template>
        </v-rating>

        <div class="text-subtitle-2"><b>{{ rating.r4.q4 }}</b></div>
          <v-rating
            active-color="amber-accent-4"
            color="blue-grey-darken-4"
            v-model="rating.r4.a4"
            :item-labels="['แย่', 'พอใช้', 'ปานกลาง', 'ดี', 'ดีมาก']"
            class="mt-1 md-2"
            item-label-position="top"
          >
          <template v-slot:item-label="props">
          <span
            :class="`text-${rcolors[props.index]}`"
            class="font-weight-black text-caption"
          >
            {{ props.label }}
          </span>
        </template>
        </v-rating>

        <div class="text-subtitle-2"><b>{{ rating.r5.q5 }}</b></div>
          <v-rating
            active-color="amber-accent-4"
            color="blue-grey-darken-4"
            v-model="rating.r5.a5"
            :item-labels="['แย่', 'พอใช้', 'ปานกลาง', 'ดี', 'ดีมาก']"
            class="mt-1 md-1"
            item-label-position="top"
          >
          <template v-slot:item-label="props">
          <span
            :class="`text-${rcolors[props.index]}`"
            class="font-weight-black text-caption"
          >
            {{ props.label }}
          </span>
        </template>
        </v-rating>

        </div>
        </v-card-actions>

        <v-spacer></v-spacer>
      <v-card-actions class="justify-center">
        <v-btn
          size="x-large"
          block
          class="buttonScore"
          @click="submitScore(isActive)"
          :disabled="!stateAllReadyScore"
        >ส่งคะแนน</v-btn>
      </v-card-actions>
      <v-card-actions class="justify-center">
        <v-btn
          variant="tonal"
          size="x-large"
          block
          color="grey-darken-4"
          @click="isActive.value = false"
        >ปิด</v-btn>
      </v-card-actions>
      </v-card>
    </template>
    </v-dialog>
  
    </div>
    </v-card-item>

    </v-card>
    </v-row>  
    
    <v-navigation-drawer
        v-model="this.isToggled"
        temporary
        class="bg-indigo-lighten-5"
         >

        <div>
        <v-avatar >
         <v-icon icon="mdi-account-circle"></v-icon>
        </v-avatar>
        <span>User</span>
        </div>

        <v-divider></v-divider>
        
        <v-list density="compact" nav>
          <v-list-item prepend-icon="mdi-file" title="ประวัติการทำแบบสอบถาม" ></v-list-item>
        </v-list>
        
        <template v-slot:append>
          <div class="pa-2">
            <v-btn block variant="tonal" @click="logout">
              ออกจากระบบ
            </v-btn>
          </div>
        </template>
    </v-navigation-drawer>
    

    </v-container>

    <v-container fluid>
    
    <!-- <div>
      <LottieAnimation ref="anim" :animation-data="LoadingAnimation" 
      :auto-play="true"
      :speed="1"
      @loopComplete="loopComplete"
      @complete="complete"
      @enterFrame="enterFrame"
      @segmentStart="segmentStart"
      @stopped="stopped"/>
    </div> -->

  </v-container>
  
  </template>
  
  <script>
  import { onMounted, ref } from "vue"
  import axios from 'axios'
  import { toast } from 'vue3-toastify';
  import 'vue3-toastify/dist/index.css';
  // import { LottieAnimation } from 'lottie-web-vue';
  // import LoadingAnimation from "./LoadingAnalysis.json";

    // // called after each loop
    // const loopComplete = () => {
    //   console.log('Loop complete')
    // }

    // // called after first loop
    // const complete = () => {
    //   console.log('First loop complete')
    // }

    // // called after first frame entered
    // const enterFrame = () => {
    //   console.log('Entered first frame')
    // }

    // // called after segment started
    // const segmentStart = () => {
    //   console.log('Segment started')
    // }

    // // called after animation stopped
    // const stopped = () => {
    //   console.log('Stopped')
    // }

  export default {
    name: 'QuizResults',
    data() {
      return {
        // score: 80,
        // points: 3,
        // passingScore: 1,
        // passingPoints: 6,
        UserPhonenumber: localStorage.getItem("UserID"),
        rcolors: ['red', 'orange', 'grey', 'cyan', 'green'],
        rating: {r1:{q1:"1. การใช้งานเว็บไซด์ง่ายไม่ซับซ้อน", a1:0}, 
                  r2:{q2:"2. เว็บไซด์มีการแสดงเนื้อหาและข้อกำหนดที่ชัดเจน", a2:0}, 
                  r3:{q3:"3. ความน่าเชื่อถือของเว็บไซด์", a3:0},
                  r4:{q4:"4. ตำแหน่งคำถามและปุ่มเลือกของเว็บไซด์มีความเหมาะสม", a4:0},
                  r5:{q5:"5. การแสดงผลลัพธ์มีความถูกต้องและชัดเจน", a5:0}
                },
        mainCard: true,
        analysisScore: null,
        percentageSleep: null,
        isToggled: false,
        mood:{emoji_1:"😊", emoji_2:"😟"},
        setState_Mood: null,
        statusSleep: {stat1: "การนอนของคุณปกติ", stat2: "คุณป่วยเป็นโรคนอนไม่หลับ" },
        setStateStatusSleep : null,
        stateSubmitScore: false,
        stateAllReadyScore: true
      };
    },
    
    methods: {
      reviewQuiz() {
        // Logic to review the quiz
      },
      async logout(){
        await localStorage.clear();
        this.$router.push('/');
      },
      toggleState() {
         this.isToggled = !this.isToggled;
      },
      submitScore(isActive) {
        isActive.value = false;
        this.stateAllReadyScore = false;
        // Additional code to handle the score submission
        //console.log(this.rating)
        const scoreData = {
          rating: this.rating,
          user_id: JSON.parse(this.UserPhonenumber).value}
          axios.post('http://146.190.88.63:11540/api/Gc3588757aa0f087c03HASA7aa0f087c03/',scoreData).then(response => console.log(response))
            .catch(error => console.log(error))

          toast.info('ขอบคุณที่ให้คะแนนเราค่ะ 🥰', {
                      position: "top-center",
                      autoClose: 3000,
                      hideProgressBar: true,
                      pauseOnHover: true,
                      draggable: true,
                      transition: "zoom",
                      theme: "auto",
                    });
      }
    }, 
    mounted() {
        // รับค่า userId จาก params
        this.analysisScore = this.$route.params.analysisScore;
        this.percentageSleep = this.$route.params.percentageSleep;
        console.log(this.analysisScore); 
        console.log(this.percentageSleep+"%");

        const score = this.analysisScore

        if(score > 5){
          this.setState_Mood = this.mood.emoji_2;
          this.setStateStatusSleep = this.statusSleep.stat2;
        }
        else if(score >= 0 && score <6){
          this.setState_Mood = this.mood.emoji_1;
          this.setStateStatusSleep = this.statusSleep.stat1;
        }
        else{
          this.setState_Mood = "❗️❓"
          this.setStateStatusSleep = "เกิดข้อพิดพลาด"
        }
    },
    components: {
      // LottieAnimation,
    },
    setup() {
    // Use ref to define reactive references
    const anim = ref();

    // Proper use of onMounted within setup()
    onMounted(() => {
      anim.value
    });

    // Return reactive references and methods
    // return {
    //   anim, LoadingAnimation, loopComplete, complete, enterFrame, segmentStart, stopped
    // };
    },
  };

  </script>
  
  <style scoped>

  .emoji_{
    font-size: 78px;
  }
  .quiz-container {
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
    font-family: 'Arial', sans-serif;
  }
  
  .result-icon {
    color: #4CAF50;
    font-size: 50px;
    margin: 5px 0;
  }
  
  .congrats-message {
    margin: 5px 0;
    font-size: 20px;
    color: #333;
  }
  
  .results {
    display: flex;
    justify-content: space-around;
    margin: 30px 0;
  }
  
  .result-box {
    width: 200px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .score-box {
    margin-right: 10px;
  }
  
  .points-box {
    margin-left: 10px;
  }
  
  .result {
    font-size: 24px;
    font-weight: bold;
    margin: 10px 0;
  }
  
  .passing {
    margin: 10px 0;
    color: #777;
    font-size: 14px;
  }
  
  .buttonEvaluate {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 20px 0;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }

  .buttonScore {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 0px 0;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .button:hover {
    background-color: #45a049;
  }
  </style>
  