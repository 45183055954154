<template>
    <div >
      <div class="signup-wrapper" >
        <div class="signup-card" >
          <form @submit.prevent="createUser">
            <!-- <header class="header">
              <v-img src="../assets/sleeping-kirby-thumb.jpg" height="85px" cover></v-img>
            </header> -->
            <br/>
            <h2>ลงทะเบียน</h2>
            <br/>
            <div class="input-group" >
            <input id="fName" class="input_fill ma-2" v-model="formData.f_name" required placeholder="ชื่อ" >
            </div>
            <div class="input-group" >
            <input id="lName" class="input_fill ma-2" v-model="formData.l_name" required placeholder="นามสกุล">
            </div>

            <div class="input-group" >
              <v-row>
                <v-col>
                  <select  id="gender" class="input_fill ma-2" v-model="formData.gender">
                    <option disabled value="ระบุเพศ" selected>ระบุเพศ</option>
                    <option v-for="item in gender" :key="item" :value="item">{{ item }}</option>
                  </select>
                </v-col>
  
                <v-col>
                  <input id="age" class="input_fill ma-2" type="number" v-model="formData.age" required placeholder="อายุ">
                </v-col>
              </v-row>
            </div>
          
            <div class="input-group" >
                <!-- Replaced v-select with select for Education -->
                <select id="education" class="input_fill ma-2" v-model="formData.education">
                  <option disabled value="การศึกษาสูงสุด" selected>การศึกษาสูงสุด</option>
                  <option v-for="item in education" :key="item" :value="item">{{ item }}</option>
                </select>
            </div>
            <div class="input-group" >
              <input id="job" class="input_fill ma-2" v-model="formData.job" required placeholder="อาชีพ">
            </div>
            <div class="input-group" >
              <v-row >
                <v-col>
                  <input id="placeJob" class="input_fill ma-2" v-model="formData.placejob" required placeholder="สถานที่ทำงาน">
                </v-col>
                <v-col class="select-container" >
                  <!-- Replaced v-select with select for Province -->
                  <select class="input_fill ma-2" v-model="formData.province">
                    <option value="ระบุจังหวัดที่อยู่" disabled selected>จังหวัดที่อยู่</option>
                    <option v-for="item in provice" :key="item" :value="item">{{ item }}</option>
                  </select>


                </v-col>
              </v-row>
            </div>
            <div class="input-group" >
                <input id="phoneNumber" class="input_fill ma-2" type="number" v-model="formData.phonenumber" required placeholder="เบอร์โทรศัพท์มือถือส่วนตัว">
            </div>
            <div class="input-group" >
                <input :type="visible ? 'text' : 'password'" @click:append-inner="visible = !visible" id="password" class="input_fill ma-2" v-model="formData.password" required placeholder="ตั้งรหัสผ่าน">
            </div>
            
  
            <v-btn class="btn_ mt-4" size="large" height="45px" color="cyan-darken-1" block type="submit">ลงทะเบียน</v-btn>
            <br/>
            <router-link to="/"><v-btn variant="text" color="orange"><h3>กลับไปหน้าหลัก</h3></v-btn></router-link>
          </form>
        </div>
      </div>
    </div>
  </template>
  

  <script >
  import axios from 'axios'
  import { toast } from 'vue3-toastify';
  import 'vue3-toastify/dist/index.css';
  import router from '../../src/router'

  export default {
    name: 'createPost',
    notify () {
        const notify_wrongphone = () => {
            toast("เบอร์โทรศัพท์นี้ถูกใช้แล้ว!", {
                autoClose: 3000,
                type: "error"
            }); 
        }
    return { notify_wrongphone };
    },
    data: () => ({
      visible: false,
      formData :{
        f_name: '',
        l_name: '',
        gender: 'ระบุเพศ',
        age: '',
        education: 'การศึกษาสูงสุด',
        job: '',
        placejob: '',
        province: 'ระบุจังหวัดที่อยู่',
        phonenumber: '',
        password: '',
      },
    //   nameRules: [
    //     v => !!v || 'Name is required',
    //     v => (v && v.length <= 10) || 'Name must be less than 10 characters',
    //   ],
    //   select: null,
      gender: [
        'ชาย',
        'หญิง',
      ],
      provice: [
        "แม่ฮ่องสอน","เชียงใหม่","ลำพูน","ลำปาง","เชียงราย","น่าน","พะเยา","แพร่","พิษณุโลก","ตาก","เพชรบูรณ์","สุโขทัย","อุตรดิตถ์",
        "นครสวรรค์","นครปฐม","นนทบุรี","ปทุมธานี","สมุทรปราการ","พระนครศรีอยุธยา","สระบุรี","ลพบุรี","ชัยนาท","สิงห์บุรี",
        "อ่างทอง","ราชบุรี","กาญจนบุรี","สุพรรณบุรี","เพชรบุรี","ประจวบคีรีขันธ์","สมุทรสาคร","สมุทรสงคราม","สุราษฎร์ธานี",
        "ชุมพร","นครศรีธรรมราช","พัทลุง","สงขลา","ภูเก็ต","กระบี่","ตรัง","พังงา","ระนอง","สตูล","ยะลา","นราธิวาส","ปัตตานี","ชลบุรี",
        "ฉะเชิงเทรา","ระยอง","ปราจีนบุรี","จันทบุรี","ตราด","นครนายก","สระแก้ว","อุดรธานี","เลย","บึงกาฬ","หนองคาย","หนองบัวลำภู",
        "สกลนคร","มุกดาหาร","นครพนม","ขอนแก่น","กาฬสินธุ์","มหาสารคาม","ร้อยเอ็ด","นครราชสีมา","ชัยภูมิ",
        "บุรีรัมย์","สุรินทร์","อุบลราชธานี","ยโสธร","ศรีสะเกษ","อำนาจเจริญ","กำแพงเพชร","พิจิตร","อุทัยธานี"
      ],
      education: [
        'ปริญญาเอก',
        'ปริญญาโท',
        'ปริญญาตรี',
        'ปวส.',
        'ปวช./ม.6 หรือ เทียบเท่า',
        'ม.3 หรือ เทียบเท่า',
        'ป.6',
      ],
      passwordRules: [
        v => !!v || 'โปรดระบุรหัสผ่าน',
      ],
      re_passwordRules: [
        v => !!v || 'โปรดกรอกรหัสผ่านอีกครั้ง',
      ],
      users: [],
    }),
   
    methods: {  
        createUser() {
        console.log("Form submitted", this.formData);
        
            axios.get('http://146.190.88.63:11540/api/a3187dcd5e46382b761d6b13f844efa633ce87/'+this.formData.phonenumber)
            .then((response) => {
                console.log(response.data);
                this.users = response.data;
                if(response.data.activities.length >= 1){
                    console.log('เบอร์ถูกใช้แล้ว')
                    toast.error("เบอร์โทรศัพท์นี้ถูกใช้แล้ว!", {
                        autoClose: 10000,
                        type: "error",
                        closeOnClick: true,
                        hideProgressBar: true,
                        theme: "colored",
                    }); 
                }
                else if(response.data.activities.length == 0){
                    axios.post('http://146.190.88.63:11540/api/e0f90ed8e9f1e9f0c9175dd0c3588757aa0f087c03/',this.formData).then(response => console.log(response))
                    .catch(error => console.log(error))
                    setTimeout(() => {
                    toast.success('ลงทะเบียนสำเร็จ 🙂', {
                      position: "top-center",
                      autoClose: 2000,
                      hideProgressBar: true,
                      closeOnClick: false,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "auto",
                    });
                        setTimeout(() => {
                            router.push({ path: '/' })
                        }, 3000);
                  }, 1000);
                }
            }).catch(error => {
                console.log(error)

                
            })
  
        },
    }
  }
  
  </script>
  
  <style scoped>
  .signup-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 110vh;
    /* background-color: #8ecae6; */
  }
   .signup-card {
    padding: 2rem;
    border-radius: 35px;
    background-color: #023047;
    width: 375px;
    box-shadow: 0 1 10px rgba(0, 0, 0, 0.3);
    text-align: center;
    color: white;
    
  }


  .input-group {
   display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: auto; /* Aligns items vertically */
  }

  .input_fill, select {
  margin: 0 auto; /* Centers the input/select within the flex container */
  display: block; /* Ensures that the input/select takes the full width */
}
  
  .input-group label {
    display: block;
    color: #8ecae6;
  }
  
  .input-group input {
    width: 100%;
    margin-top: 0.8rem;
    border-radius: 15px;
    border: none;
  }

  .input-group label {
    display: block;
    color: #8ecae6;
  }

  .input_fill {
    width: 100%;
    padding: 0.6rem;
    margin-top: 0.2rem;
    border-radius: 15px;
    background-color: #e1f2fa;
  }

  .btn_ {
    width: 100%;
    padding: 0.8rem;
    border: none;
    border-radius: 20px;
    background-color: #61cafa;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  </style>