
<script >
import sha from 'sha.js';
export default {
  
  data() {
    return {
		questions: [
			{ type: 'time', text: '1.ในช่วงระยะเวลา 1 เดือนที่ผ่านมา ส่วนใหญ่ท่านมักเข้านอนเวลากี่โมง?', answer: '00:00' },
			{ type: 'number', text: '2.ในช่วงระยะเวลา 1 เดือนที่ผ่านมา ส่วนใหญ่ท่านต้องใช้เวลานานเท่าไหร่ (นาที) จึงจะนอนหลับ?', answer: null },
			{ type: 'time', text:'3.ในช่วงระยะเวลา 1 เดือนที่ผ่านมา ส่วนใหญ่ท่านตื่นนอนตอนเช้าเวลากี่โมง', answer:'00:00' },
			{ type: 'select', text: '4.ในช่วงระยะเวลา 1 เดือนที่ผ่านมา ท่านนอนหลับได้จริงเป็นเวลากี่ชั่วโมงต่อคืน (คำตอบอาจแตกต่างจากระยะเวลารวมทั้งหมดตั้งแต่เริ่มเข้านอนจนถึงตื่นนอน)', answer: 0},
			// Add more questions as needed
		],
		questions2: [
		{ 	type: 'radio', 
				text: '5.1 นอนไม่หลับหลังจากเข้านอนไปแล้วนานกว่า 30 นาที', 
				options: [
					{text: 'ไม่เคยเลยในช่วงระยะเวลา 1 เดือนที่ผ่านมา', value:0}, 
					{text: 'น้อยกว่า 1 ครั้งต่อสัปดาห์', value:1}, 
					{text: '1 หรือ 2 ครั้งต่อสัปดาห์', value:2}, 
					{text:'3 ครั้งต่อสัปดาห์ขึ้นไป', value:3}],
				answer:null },
			{ 	type: 'radio',
				text: '5.2 รู้สึกตัวตื่นขึ้นระหว่างนอนหลับกลางดึก หรือตื่นเช้ากว่าเวลาที่ตั้งใจไว้', 
				options: [
					{text: 'ไม่เคยเลยในช่วงระยะเวลา 1 เดือนที่ผ่านมา', value:0},
					{text: 'น้อยว่า 1 ครั้งต่อสัปดาห์', value:1}, 
					{text: '1 หรือ 2 ครั้งต่อสัปดาห์', value:2}, 
					{text: '3 ครั้งต่อสัปดาห์ขึ้นไป', value:3}], 
					answer:null },
			{
				type: 'radio',
				text: '5.3 ตื่นเพื่อไปเข้าห้องน้ำ',
				options: [
					{text: 'ไม่เคยเลยในช่วงระยะเวลา 1 เดือนที่ผ่านมา', value: 0},
					{text: 'น้อยว่า 1 ครั้งต่อสัปดาห์', value: 1},
					{text: '1 หรือ 2 ครั้งต่อสัปดาห์', value: 2},
					{text: '3 ครั้งต่อสัปดาห์ขึ้นไป', value: 3}
				],
				answer: null
				},
				{
				type: 'radio',
				text: '5.4 หายใจไม่สะดวก',
				options: [
					{text: 'ไม่เคยเลยในช่วงระยะเวลา 1 เดือนที่ผ่านมา', value: 0},
					{text: 'น้อยว่า 1 ครั้งต่อสัปดาห์', value: 1},
					{text: '1 หรือ 2 ครั้งต่อสัปดาห์', value: 2},
					{text: '3 ครั้งต่อสัปดาห์ขึ้นไป', value: 3}
				],
				answer: null
				},
				{
				type: 'radio',
				text: '5.5 ไอ หรือ กรน เสียงดัง',
				options: [
					{text: 'ไม่เคยเลยในช่วงระยะเวลา 1 เดือนที่ผ่านมา', value: 0},
					{text: 'น้อยว่า 1 ครั้งต่อสัปดาห์', value: 1},
					{text: '1 หรือ 2 ครั้งต่อสัปดาห์', value: 2},
					{text: '3 ครั้งต่อสัปดาห์ขึ้นไป', value: 3}
				],
				answer: null
				},
				{
				type: 'radio',
				text: '5.6 รู้สึกหนาวเกินไป',
				options: [
					{text: 'ไม่เคยเลยในช่วงระยะเวลา 1 เดือนที่ผ่านมา', value: 0},
					{text: 'น้อยว่า 1 ครั้งต่อสัปดาห์', value: 1},
					{text: '1 หรือ 2 ครั้งต่อสัปดาห์', value: 2},
					{text: '3 ครั้งต่อสัปดาห์ขึ้นไป', value: 3}
				],
				answer: null
				},
				{
				type: 'radio',
				text: '5.7 รู้สึกร้อนเกินไป',
				options: [
					{text: 'ไม่เคยเลยในช่วงระยะเวลา 1 เดือนที่ผ่านมา', value: 0},
					{text: 'น้อยว่า 1 ครั้งต่อสัปดาห์', value: 1},
					{text: '1 หรือ 2 ครั้งต่อสัปดาห์', value: 2},
					{text: '3 ครั้งต่อสัปดาห์ขึ้นไป', value: 3}
				],
				answer: null
				},
				{
				type: 'radio',
				text: '5.8 ฝันร้าย',
				options: [
					{text: 'ไม่เคยเลยในช่วงระยะเวลา 1 เดือนที่ผ่านมา', value: 0},
					{text: 'น้อยว่า 1 ครั้งต่อสัปดาห์', value: 1},
					{text: '1 หรือ 2 ครั้งต่อสัปดาห์', value: 2},
					{text: '3 ครั้งต่อสัปดาห์ขึ้นไป', value: 3}
				],
				answer: null
				},
				{
				type: 'radio',
				text: '5.9 รู้สึกปวด',
				options: [
					{text: 'ไม่เคยเลยในช่วงระยะเวลา 1 เดือนที่ผ่านมา', value: 0},
					{text: 'น้อยว่า 1 ครั้งต่อสัปดาห์', value: 1},
					{text: '1 หรือ 2 ครั้งต่อสัปดาห์', value: 2},
					{text: '3 ครั้งต่อสัปดาห์ขึ้นไป', value: 3}
				],
				answer: null
				},
			{ type: 'text', text: '5.10 เหตุผลอื่น ถ้ามี กรุณาระบุ _____', answer:''
			},
			{ type: 'radio', text: 'จากเหตุผลในข้อ 5.10 ในช่วงระยะเวลา 1 เดือนที่ผ่านมา เกิดบ่อยเพียงใด',
			options: [
				{text: 'ไม่เคยเลยในช่วงระยะเวลา 1 เดือนที่ผ่านมา', value:0}, 
				{text: 'น้อยว่า 1 ครั้งต่อสัปดาห์', value:1}, 
				{text: '1 หรือ 2 ครั้งต่อสัปดาห์', value:2},
				{text: '3 ครั้งต่อสัปดาห์ขึ้นไป', value:3}
			], answer:''
			},
		],
		questions3: [
		{
				type: 'radio', 
				text: '6. ในช่วงระยะเวลา 1 เดือนที่ผ่านมา ท่านคิดว่าคุณภาพการนอนหลับโดยรวมของท่านเป็นอย่างไร', 
				options:[
					{text: 'ดีมาก', value: 0}, 
					{text: 'ค่อนข้างดี', value: 1}, 
					{text: 'ค่อนข้างแย่', value: 2}, 
					{text: 'แย่มาก', value: 3}
				], 
				answer:null
				},
				{
				type: 'radio', 
				text: '7. ในช่วงระยะเวลา 1 เดือนที่ผ่านมา ท่านใช้ยาเพื่อช่วยในการนอนหลับ บ่อยเพียงใด (ไม่ว่าจะตามใบสั่งแพทย์ หรือ หาซื้อมาเอง)', 
				options:[
					{text: 'ไม่เคยเลยในช่วงระยะเวลา 1 เดือนที่ผ่านมา', value: 0}, 
					{text: 'น้อยว่า 1 ครั้งต่อสัปดาห์', value: 1}, 
					{text: '1 หรือ 2 ครั้งต่อสัปดาห์', value: 2}, 
					{text: '3 ครั้งต่อสัปดาห์ขึ้นไป', value: 3}
				], 
				answer:null
				},
				{
				type: 'radio', 
				text: '8. ในช่วงระยะเวลา 1 เดือนที่ผ่านมา ท่านมีปัญหาง่วงนอนหรือเผลอหลับ ขณะขับขี่ยานพาหนะ ขณะรับประมานอาหาร หรือขณะเข้าร่วมกิจกรรมทางสังคมต่าง ๆ บ่อยเพียงใด', 
				options:[
					{text: 'ไม่เคยเลยในช่วงระยะเวลา 1 เดือนที่ผ่านมา', value: 0}, 
					{text: 'น้อยว่า 1 ครั้งต่อสัปดาห์', value: 1}, 
					{text: '1 หรือ 2 ครั้งต่อสัปดาห์', value: 2}, 
					{text: '3 ครั้งต่อสัปดาห์ขึ้นไป', value: 3}
				], 
				answer:null
				},
				{
				type: 'radio', 
				text: '9. ในช่วงระยะเวลา 1 เดือนที่ผ่านมา ท่านมีปัญหาเกี่ยวกับความกระตือรือร้นในการทำงานให้สำเร็จมากน้อยเพียงใด', 
				options:[
					{text: 'ไม่มีปัญหาเลยแม้แต่น้อย', value: 0},
					{text: 'ปัญหาเพียงเล็กน้อย', value: 1},
					{text: 'ค่อนข้างที่จะเป็นปัญหา', value: 2},
					{text: 'เป็นปัญหาอย่างมาก', value: 3}
				], 
				answer:null
				}
			
		],
			inputValue: '',
			userToken: null,
			isFocused: false,
			sheet_1: true,
			sheet_2: false,
			sheet_3: false,
			score2_toDo: 0,
			score5_1_toDo: 0,
			analysis2_51:0,
			analysis52_510:0,
			analysis8_9: 0,
			percentageSleep_: 0,
			answers_quiz: {_1:null, _2:null, _3:null, _4:null, _5_1:null, _5_2:null, _5_3:null, _5_4:null, _5_5:null
							, _5_6:null, _5_7:null, _5_8:null, _5_9:null, _5_10_1:null, _5_10_2:null, _6:null, _7:null
							, _8:null, _9:null},
			percentage_sleep: 0,
			total_score: 0,
			numbers: Array.from({ length: 10 }, (_, i) => i + 1), // สร้างอาร์เรย์ตัวเลข 1-10
		};
  },

  computed: {
    isDisabled() {
      // ตรวจสอบว่าทุกคำถามใน questionsมีคำตอบหรือไม่
      const allQuestionsAnswered = this.questions.every(q => q.answer !== null && q.answer !== 0);
      // ปุ่มจะถูก disabled ถ้ายังมีคำถามที่ไม่ได้รับคำตอบ
      return !allQuestionsAnswered;
    },
	isDisabled2() {
      // ตรวจสอบว่าคำถามที่ไม่ใช่ index 9 และ 10 ใน questions2 มีคำตอบหรือไม่
      const allQuestionsAnswered = this.questions2.every((q, index) => {
      // ข้ามการตรวจสอบสำหรับคำถามที่ index 9 และ 10
      if (index === 9 || index === 10) return true;

      // สำหรับคำถามอื่นๆ, ตรวจสอบว่ามีคำตอบหรือไม่
      return q.answer !== null && q.answer !== '';
      });

    // ปุ่มจะถูก disabled ถ้ายังมีคำถามที่ไม่ได้รับคำตอบ
    return !allQuestionsAnswered;
    },
	isDisabled3() {
      // ตรวจสอบว่าทุกคำถามใน questionsมีคำตอบหรือไม่
      const allQuestionsAnswered = this.questions3.every(q => q.answer !== null);
      // ปุ่มจะถูก disabled ถ้ายังมีคำถามที่ไม่ได้รับคำตอบ
      return !allQuestionsAnswered;
    },

  },

  methods: {
    submitSurvey_1() {
      // Handle the submission of the survey
    console.log(this.questions);
    this.sheet_1 = false;
	this.sheet_2 = true;
	this.sheet_3 = false;
    },
	submitSurvey_2() {
	window.scrollTo(0, 0);
      // Handle the submission of the survey
    // console.log(this.questions2[0].answer.value);
    this.sheet_1 = false;
	this.sheet_2 = false;
	this.sheet_3 = true;
    },
	async submitSurvey_3() {
	window.scrollTo(0, 0);
      // Handle the submission of the survey
    console.log(this.questions3);
	await this._data();
	await this._HistoryQuiz();
	const analysisScore_ = this._data();
	console.log("////////Analysis/////////");
	console.log(this._data());
	console.log("////////Object History/////////");
	console.log(this.answers_quiz);
	const timestamp = Date.now();
	this.$router.push({ name: 'analysis', params: { userToken: await sha('sha256').update(timestamp).digest('hex'), analysisScore: analysisScore_, percentageSleep: this.percentageSleep_ }});
    },
	_HistoryQuiz(){
		this.answers_quiz[0]._1 = this.questions[0].answer;
		this.answers_quiz[0]._2 = this.questions[1].answer;
		this.answers_quiz[0]._3 = this.questions[2].answer;
		this.answers_quiz[0]._4 = this.questions[3].answer;
		this.answers_quiz[0]._5_1 = this.questions2[0].answer.value;
		this.answers_quiz[0]._5_2 = this.questions2[1].answer.value;
		this.answers_quiz[0]._5_3 = this.questions2[2].answer.value;
		this.answers_quiz[0]._5_4 = this.questions2[3].answer.value;
		this.answers_quiz[0]._5_5 = this.questions2[4].answer.value;
		this.answers_quiz[0]._5_6 = this.questions2[5].answer.value;
		this.answers_quiz[0]._5_7 = this.questions2[6].answer.value;
		this.answers_quiz[0]._5_8 = this.questions2[7].answer.value;
		this.answers_quiz[0]._5_9 = this.questions2[8].answer.value;
		this.answers_quiz[0]._5_10_1 = this.questions2[9].answer.value;
		this.answers_quiz[0]._5_10_2 = this.questions2[10].answer.value;
		this.answers_quiz[0]._6 = this.questions3[0].answer.value;
		this.answers_quiz[0]._7 = this.questions3[1].answer.value;
		this.answers_quiz[0]._8 = this.questions3[2].answer.value;
		this.answers_quiz[0]._9 = this.questions3[3].answer.value;
		this.percentage_sleep = this.percentageSleep_;
		this.total_score = this._data();
	},
	_data() {
		const score1_3 = this.calculateTimeDifferenceInHours(this.questions[0].answer, this.questions[2].answer);
		console.log(score1_3)
		const score1_4 = (this.questions[3].answer / score1_3)*100 ;
		this.percentageSleep_ = score1_4;
		console.log(score1_4)
		console.log("/////////////////////////////////////////////");
////////////////////////////////////////////////////////////////////////////////////////
		const score_2 = this.questions[1].answer;
		if(this.score_2 <= 15){
			this.score2_toDo = 0;
		}
		else if(score_2 >= 16 && score_2 <= 30){
			this.score2_toDo = 1;
		}
		else if(score_2 >= 31 && score_2 <= 60){
			this.score2_toDo = 2;
		}
		else if(score_2 > 60){
			this.score2_toDo = 3;
		}
		// console.log(this.questions[1].answer)
		// console.log(this.score2_toDo);

		const score5_1 = this.questions2[0].answer.value;

		if(this.score5_1 <= 15){
			this.score5_1_toDo = 0;
		}
		else if(score5_1 >= 16 && score5_1 <= 30){
			this.score5_1_toDo = 1;
		}
		else if(score5_1 >= 31 && score5_1 <= 60){
			this.score5_1_toDo = 2;
		}
		else if(score5_1 > 60){
			this.score5_1_toDo = 3;
		}

		const score2_51Cal = this.score2_toDo + this.questions2[0].answer.value;
		// console.log(this.questions2[0].answer.value)
		console.log(score2_51Cal);

		if(score2_51Cal < 1 && score2_51Cal === 0){
			this.analysis2_51 = 0;
		}
		else if(score2_51Cal >= 1 && score2_51Cal < 3){
			this.analysis2_51 = 1;
		}
		else if(score2_51Cal >= 3 && score2_51Cal < 5){
			this.analysis2_51 = 2;
		}
		else if(score2_51Cal >= 5 && score2_51Cal < 7){
			this.analysis2_51 = 3;
		}
		console.log(this.analysis2_51);
		console.log("/////////////////////////////////////////////");
////////////////////////////////////////////////////////////////////////////////////////		
		
		console.log(this.questions2);
		const score52_510 = null;
		// this.score52_510 = this.questions2[1].answer.value+this.questions2[2].answer.value+this.questions2[3].answer.value+this.questions2[4].answer.value+
		// 					this.questions2[5].answer.value+this.questions2[6].answer.value+this.questions2[7].answer.value+this.questions2[8].answer.value+
		// 					this.questions2[10].answer.value;

		if (this.questions2[10].answer !== null) {
			this.score52_510 = this.questions2[1].answer.value+this.questions2[2].answer.value+this.questions2[3].answer.value+this.questions2[4].answer.value+
							this.questions2[5].answer.value+this.questions2[6].answer.value+this.questions2[7].answer.value+this.questions2[8].answer.value+
							this.questions2[10].answer.value;
		} else {
			this.score52_510 = this.questions2[1].answer.value+this.questions2[2].answer.value+this.questions2[3].answer.value+this.questions2[4].answer.value+
							this.questions2[5].answer.value+this.questions2[6].answer.value+this.questions2[7].answer.value+this.questions2[8].answer.value

		}
		console.log(score52_510);

		if(score52_510 < 1 && score52_510 === 0){
			this.analysis52_510 = 0;
		}
		else if(score52_510 >= 1 && score52_510 < 10){
			this.analysis52_510 = 1;
		}
		else if(score52_510 >= 10 && score52_510 < 19){
			this.analysis52_510 = 2;
		}
		else if(score52_510 >= 19 && score52_510 < 28){
			this.analysis52_510 = 3;
		}
		else{
			this.analysis52_510 = 0;
		}
		console.log(this.analysis52_510);

		console.log("/////////////////////////////////////////////");

		const score_6 = this.questions3[0].answer.value;
		console.log(score_6);
		console.log("/////////////////////////////////////////////");
		const score_7 = this.questions3[1].answer.value;
		console.log(score_7);
		console.log("/////////////////////////////////////////////");


		const score8_9 = this.questions3[2].answer.value + this.questions3[3].answer.value;
		if(score8_9 === 0 ){
			this.analysis8_9 = 0;
		}
		else if(score8_9 > 0 && score8_9 < 3){
			this.analysis8_9 = 1;
		}
		else if(score8_9 >= 3 && score8_9 < 5){
			this.analysis8_9 = 2;
		} 
		else if(score8_9 >= 5 && score8_9 < 7){
			this.analysis8_9 = 3;
		} 
		else{
			this.analysis8_9 = 0;
		}
		console.log(this.analysis8_9);


		return  this.analysis2_51 + this.analysis52_510 + score_6 + score_7 + this.analysis8_9
	},
	calculateTimeDifferenceInHours(startTime, endTime){
		// แปลงเวลาเริ่มต้นและเวลาสิ้นสุดเป็นวินาที
		const start = startTime.split(":").map(Number);
		const end = endTime.split(":").map(Number);
		const startSeconds = start[0] * 3600 + start[1] * 60;
		const endSeconds = end[0] * 3600 + end[1] * 60;

		// คำนวณความแตกต่างของเวลาในหน่วยวินาที
		let diffSeconds = endSeconds - startSeconds;

		// แปลงความแตกต่างให้อยู่ในหน่วยชั่วโมง
		let diffHours = diffSeconds / 3600;

		// สำหรับกรณีที่เวลาสิ้นสุดอยู่ในวันถัดไป
		if (diffHours < 0) {
		diffHours += 24; // เพิ่ม 24 ชั่วโมง
		}

		return diffHours;

	},
	handleFocus(index) {
	if (this.questions[index]) {
		this.questions[index].isFocused = true; // สำหรับ questions
	} else if (this.questions2[index - this.questions.length]) { // หาก index เกิน questions
		this.questions2[index - this.questions.length].isFocused = true; // สำหรับ questions2
	}
	},
	handleBlur(index) {
	if (this.questions[index]) {
		this.questions[index].isFocused = false; // สำหรับ questions
	} else if (this.questions2[index - this.questions.length]) { // หาก index เกิน questions
		this.questions2[index - this.questions.length].isFocused = false; // สำหรับ questions2
	}
	},
	setStateBack1() {
		this.sheet_1 = true;
		this.sheet_2 = false;
	},
	setStateBack2() {
		this.sheet_1 = false;
		this.sheet_2 = true;
		this.sheet_3 = false;
	},
	getItemWithExpiry(key) {
    const itemStr = localStorage.getItem(key);

    // ถ้าไม่พบ item ใน localStorage, คืนค่า null
    if (!itemStr) {
        return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    // ตรวจสอบว่าข้อมูลหมดอายุหรือยัง
    if (now.getTime() > item.expiry) {
        // ถ้าหมดอายุแล้ว, ลบข้อมูลออกจาก localStorage และคืนค่า null
        localStorage.removeItem(key);
        return null;
    }
	console.log(item.value)
    return item.value;
  }
  },
  mounted() {
    this.userToken = localStorage.getItem('userToken');
	console.log(this.userToken);
    if (this.userToken == null) {
      // หากไม่พบ userToken, ใช้ this.$router.push นำทางไปยังหน้า login
      this.$router.push('/login');
    }
	
	this.getItemWithExpiry('userToken');
    
	
    },
  handleRadioChange(value) {
      // แปลงค่าที่รับมาเป็นตัวเลขแล้วอัพเดท question.answer
      this.questions2.answer = Number(value);
	console.log(this.questions2.answer = Number(value))
    },

};

</script>


<template>
	

	<v-app-bar
        color="primary"
        density="compact"
      >

	<v-col cols="auto">
		<v-btn variant="tonal" v-if="sheet_2 === true" @click="setStateBack1">
			<v-icon
			start
			icon="mdi-arrow-left"
			></v-icon>
			BACK
		</v-btn>
		<v-btn variant="tonal" v-if="sheet_3 === true" @click="setStateBack2">
			<v-icon
			start
			icon="mdi-arrow-left"
			></v-icon>
			BACK
		</v-btn>
	</v-col>

	</v-app-bar>
	
	<v-container fluid>
	<v-row align="center" justify="center" >
    <v-card flat
        class="mx-auto"
        width=auto
        height="auto">
      <v-row justify="center" >
		<v-col >

            <v-card-item v-if="sheet_1 === true ">
            <v-card-text><h3><b>คำถามต่อไปนี้เกี่ยวข้องกับพฤติกรรมการนอนของท่านในช่วงระยะเวลา 1 เดือนที่ผ่านมา</b>
			</h3></v-card-text>
            <v-divider class="my-0"></v-divider>
			<v-card-text>คำตอบของท่านควรบ่งบอกสิ่งที่ใกล้เคียงความเป็นจริงมากที่สุด และเป็นสิ่งที่เกิดขึ้นกับตัวท่านเป็นส่วนใหญ่ทั้งในเวลากลางวันและกลางคืน โปรดตอบทุกคำถาม</v-card-text>
            <div v-for="(question, index) in questions" :key="index" class="my-0">
			<v-card-text class="subtitle-1 ">{{ question.text }}</v-card-text>
  
			<v-card-actions class="input-container" v-if="question.type === 'time'">
				<input
				id="ans_1"
				locale="en"
				type="time"
				v-model="question.answer"
				:class="{'is-focused': question.isFocused}"
				@focus="handleFocus(index)"
				@blur="handleBlur(index)"
				required
				>
			</v-card-actions>
			
			<v-card-actions class="input-container" v-else-if="question.type === 'number'">
				<input
				v-model="question.answer"
				type="number" 
				:class="{'is-focused': question.isFocused}"
				@focus="handleFocus(index)"
				@blur="handleBlur(index)"
				placeholder="ใส่ตัวเลข (นาที)"
				required
				/>
			</v-card-actions>

			<v-card-actions class="select-container" v-else-if="question.type === 'select'" >
				<select v-model="question.answer" >
				<option value=0 disabled >เลือกจำนวน(ชั่วโมง)</option> <!-- Placeholder -->
				<option v-for="number in numbers" :key="number" :value="number" >
					{{ number }}
				</option>
				</select>
			</v-card-actions>

            </div>
            <v-btn :disabled="isDisabled" rounded="lg" size="large" variant="flat" color="primary" dark class="mt-5" @click="submitSurvey_1">ถัดไป</v-btn>
		</v-card-item>
<!--  -->
		<v-card-item id="sheet_2" v-if="sheet_2 === true" >
            <v-card-text><h3><b>5. ในช่วงระยะเวลา 1 เดือนที่ผ่านมา ท่านมีปัญหาการนอนหลับเนื่องจากเหตุผลต่อไปนี้ บ่อยเพียงใด</b></h3></v-card-text>
            <v-divider class="my-0"></v-divider>
            <div v-for="(question, index) in questions2" :key="index" class="my-0">
			<v-card-text class="subtitle-1 ">{{ question.text }}</v-card-text>
			
			<v-card-actions v-if="question.type === 'radio' ">
                <v-radio-group v-model="question.answer" row class="pt-0" required>
					<v-radio 
					v-for="(option, idx) in question.options"
					:key="idx"
					:value="option"
					color="primary"
					>
					<template v-slot:label>
						<div class="radio-label">{{ option.text }}</div>
					</template>
					</v-radio>
                </v-radio-group>
            </v-card-actions>

			<v-card-actions class="input-container" v-else-if="question.type === 'text'">
				<input
				v-model="question.answer"
				type="text" 
				:class="{'is-focused': question.isFocused}"
				@focus="handleFocus(index)"
				@blur="handleBlur(index)"
				placeholder="หากมีโปรดระบุ"
				required
				/>
			</v-card-actions>
        
		</div>
			<v-btn :disabled="isDisabled2" rounded="lg" size="large" variant="flat" color="primary" dark class="mt-0" @click="submitSurvey_2">ถัดไป</v-btn>
		</v-card-item>
<!--  -->
		<v-card-item id="sheet_3" v-if="sheet_3 === true" >
            <v-card-text><h3><b>คำตอบของท่านควรบ่งบอกสิ่งที่ใกล้เคียงความเป็นจริงมากที่สุด และเป็นสิ่งที่เกิดขึ้นกับตัวท่านเป็นส่วนใหญ่ทั้งในเวลากลางวันและกลางคืน โปรดตอบทุกคำถาม</b></h3></v-card-text>
            <v-divider class="my-0"></v-divider>
            <div v-for="(question, index) in questions3" :key="index" class="my-0">
			<v-card-text class="subtitle-1 ">{{ question.text }}</v-card-text>

			<v-card-actions v-if="question.type === 'radio' ">
                <v-radio-group v-model="question.answer" row class="pt-0" required>
					<v-radio 
					v-for="(option, idx) in question.options"
					:key="idx"
					:value="option"
					color="primary"
					>
					<template v-slot:label>
						<div class="radio-label">{{ option.text }}</div>
					</template>
					</v-radio>
                </v-radio-group>
            </v-card-actions>
				
			</div>
			<v-btn :disabled="isDisabled3" rounded="lg" size="large" variant="flat" color="orange" dark class="mt-0" @click="submitSurvey_3">ส่งแบบประเมิน</v-btn>
		</v-card-item>

		</v-col>
      </v-row>
    </v-card>
	</v-row>
  </v-container>
</template>


<style scoped>

 /* การปรับปรุงสไตล์ */
.subtitle-1 {
  font-size: 15px; /* ปรับขนาดข้อความให้เล็กลง */
  overflow-wrap: break-word; /* ให้ข้อความหักลงบรรทัดใหม่ */
  max-width: 100%; /* กำหนดความกว้างสูงสุด */

}

.v-sheet, .v-card-item {
  border-radius: 16px;
  max-width: 100%; /* ตรวจสอบให้แน่ใจว่า v-sheet ไม่เกินขนาดหน้าจอ */
}

.v-btn {
  width: 100%;
}

.v-icon.v-icon {
  font-size: 20px;
}
.headline {
  font-family: 'SF UI Display' !important;
}

/* สไตล์ทั่วไป */
.v-card, .v-card-item {
  max-width: 100%; /* กำหนดความกว้างสูงสุด */
  overflow: hidden; /* ป้องกันเนื้อหาล้ำออกนอกบัตร */
}

.input-container input {
  width: 100%; /* ขยายให้เต็มความกว้างของคอนเทนเนอร์ */
  padding: 15px 20px; /* เพิ่ม padding เพื่อให้ข้อความและกล่องมีขนาดใหญ่ขึ้น */
  margin: 0px 0; /* ปรับขนาด margin เพื่อให้มีพื้นที่รอบกล่องมากขึ้น */
  font-size: 18px; /* เพิ่มขนาดของ font */
  border: 2px solid #ccc; /* สีขอบปกติ */
  border-radius: 10px; /* ขอบมน */
  box-sizing: border-box; /* ให้ padding และ border อยู่ในขอบเขต width */
  outline: none; /* ลบเส้นขอบเดิมเมื่อโฟกัส */
  transition: border-color 0.5s; /* ทำให้การเปลี่ยนสีเป็นไปอย่างราบรื่น */
}

.input-container input.is-focused {
  border-color: #00BFFF; /* สีขอบเมื่อโฟกัส */
}

.input-container input::placeholder {
  color: #aaa; /* สีของข้อความ placeholder */
}

.select-container select {
  width: 100%; /* ขยายให้เต็มความกว้างของคอนเทนเนอร์ */
  padding: 15px 20px; /* เพิ่ม padding เพื่อให้ข้อความและกล่องมีขนาดใหญ่ขึ้น */
  margin: 0px 0; /* ปรับขนาด margin เพื่อให้มีพื้นที่รอบกล่องมากขึ้น */
  font-size: 18px; /* เพิ่มขนาดของ font */
  border: 2px solid #ccc; /* สีขอบปกติ */
  border-radius: 10px; /* ขอบมน */
  box-sizing: border-box; /* ให้ padding และ border อยู่ในขอบเขต width */
  outline: none; /* ลบเส้นขอบเดิมเมื่อโฟกัส */
  transition: border-color 0.5s; /* ทำให้การเปลี่ยนสีเป็นไปอย่างราบรื่น */
}

.select-container select:focus {
  border-color: #00BFFF; /* สีขอบเมื่อโฟกัส */
}

@media (max-width: 600px) {
  .input-container input {
    /* ปรับขนาดและสไตล์สำหรับหน้าจอขนาดเล็ก */
  }}
.radio-label {
  font-size: 16px; /* หรือขนาดที่ต้องการ */
}
</style>