<template>
   <v-app-bar
        height="50"
        color="teal-darken-1"
   
      >
        <template v-slot:image>
          <v-img
            gradient="to top right, rgba(0, 255, 204,.8), rgba(2, 48, 71, 1)"
          ></v-img>
        </template>

        <template v-slot:prepend>
          <v-app-bar-nav-icon></v-app-bar-nav-icon>
        </template>

        <v-app-bar-title>แพทย์ไทยนอนหลับ</v-app-bar-title>

      </v-app-bar>
 <v-container>

  <v-sheet margin="1" class="align-center justify-center flex-wrap text-center mx-auto px-4" rounded width="100%">
    <div>
    <v-img
      src="../assets/home2.png"
      max-height="150"
      ></v-img>
      <br/>
    </div>

    <v-row justify="space-around">
    <ul align="center" justify="center">
      <h4 class="text-h5 font-weight-bold mb-4 text-Blue-accent-2 font-weight-black" >ยินดีต้อนรับ</h4>
      <p class="mb-4 text-medium-emphasis text-body-2" >นี่คือต้นแบบของ Web Application สำหรับการรักษาโรคนอนไม่หลับ ซึ่งเป็นส่วนหนึ่งของงานวิจัยในระดับปริญญาเอกของ <a class="text-blue-accent-3">นาวสาวมนัสนันท์ พันธ์เกษมสุข</a>  ผู้ศึกษาในสาขากัญชาศาสตร์และพืชสมุนไพร เพื่อการพัฒนาท้องถิ่น มหาวิทยาลัยพายัพ ดิฉันจึงขอความร่วมมือจากผู้เข้าร่วมวิจัยในการทดลองใช้งาน Web Application นี้ 
        และขอความกรุณาทำแบบประเมินหลังการใช้งาน เพื่อให้ผู้วิจัยสามารถนำข้อมูลที่ได้ไปวิเคราะห์และปรับปรุงได้อย่างมีประสิทธิภาพในอนาคต ขอขอบพระคุณสำหรับความร่วมมืออย่างยิ่งค่ะ</p>
    </ul>
    </v-row>

  <v-form >
    <!-- <v-checkbox
      v-model="checkbox"
      :rules="[v => !!v || 'You must agree to continue!']"
      label="ยอมรับเงื่อนไข"
      value="1"
      required
    ></v-checkbox> -->

    <v-row align="center" justify="center">
      <v-col cols="auto">
        <router-link to="/consent"><v-btn color="orange-lighten-2" size="large">ลงทะเบียน</v-btn></router-link>
      </v-col>
      <v-col cols="auto">
        <router-link to="/login"> <v-btn color="green-accent-2" size="large" prepend-icon="mdi-login">เข้าสู่ระบบ</v-btn></router-link>
      </v-col>
    </v-row>
  </v-form>
</v-sheet>

<br/>
 </v-container>

 <v-footer
    class="bg-indigo-lighten-1 text-center d-flex flex-column"
  >
    <div class="pt-0">
     Close Beta-version © Copyright  —  {{ new Date().getFullYear() }}
    </div>



    <div>
      <strong>Cannabis and Medicinal Plants For Local Development, Payap University</strong>
    </div>
  </v-footer>
</template>

<script>

export default ({

});
</script>

<style>
</style>
