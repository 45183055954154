<template>
  <div>
    <br/>
    <v-sheet margin="1" class="align-center justify-center flex-wrap text-center mx-auto px-4" rounded width="100%">
    <ul align="center" justify="center">
        <h4 class="text-h5 font-weight-bold mb-4 text-red-accent-2">หนังสือยินยอม</h4>
    <ul align="left" justify="center">
      <a class="text-h7 font-weight-bold mb-4">การศึกษา:  <a class="text-h7 font-weight-bold mb-4 text-blue-accent-2">การแพทย์ทางไกลสำหรับแพทย์แผนไทย การศึกษาเกี่ยวกับการบริหารจัดการโรคนอนไม่หลับด้วยสารแคนนาบิไดออล</a></a>
      <br/>
      <a class="text-h7 font-weight-bold mb-4">ชื่อผู้ทำการศึกษา:  <a class="text-h7 font-weight-bold mb-4 text-blue-accent-2">นางสาวมนัสนันท์ พันธ์เกษมสุข</a></a>
    </ul>
      <br/>
      <p class="mb-4 text-medium-emphasis text-body-2" align="left">ก่อนที่ท่านจะยินยอมเข้าร่วมงานวิจัย ดิฉันขอกล่าวถึงวัตถุประสงค์การทดลองใช้ต้นแบบ Web Application นี้
        เพื่อทดสอบว่าการทำแบบประเมินคุณภาพการนอนหลับผ่าน Web Application นี้ สามารถประเมินได้อย่างถูกต้อง และเข้ากับการเปลี่ยนแปลงของยุคสมัยที่ทุกท่านมีโทรศัพท์สมาร์ทโฟน ซึ่งง่ายต่อการเข้าถึง Web Application และรูบแปปการใช้งานที่ง่าย เข้าถึงได้ง่าย
        ดิฉันเลือกหมอแพทย์ไทยเป็นผู้เข้าร่วมงานวิจัย เนื่องจากท่านผ่านการอบรมกัญชา ท่านจึงเป็นผู้มีความรู้และความเข้าใจเป็นอย่างดีในการใช้ยาแผนไทยที่สาร CBD เป็นส่วนประกอบ</p>
    </ul>
    <div >
    <v-checkbox value="checked" label="ยินยอม" :rules="[v => !!v || 'กรุณากดยินยอม']" required v-model="agreement" name="agreement"></v-checkbox>
    </div>
    <br/>
    <v-row align="center" justify="center">
        <v-btn color="orange-darken-2" size="large" width="200px" height="50px" :disabled="!agreement" href="/signup">
          ถัดไป
        </v-btn>
    </v-row>
    <v-row align="center" justify="center"><br/><br/><br/><router-link to="/"><u>ย้อนกลับ</u></router-link></v-row>
    </v-sheet>
  </div>
</template>

<script>
export default {
    data() {
    return {
      agreement: false,
    };
  },
}
</script>

<style>

</style>