import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SignUp from '../views/SignUp.vue'
import Consent from '../views/ConsentPage.vue'
import Quiz_ from '../views/quiz/Quiz_.vue'
import Analysis from '../views/quiz/AnalyzePage.vue'
import LoginPage from '../views/LoginPage.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignUp
  },
  {
    path: '/consent',
    name: 'consent',
    component: Consent
  },
  {
    path: '/questionnaire/:userToken',
    name: 'questionnaire',
    component: Quiz_
  },
  {
    // path: '/analysis',
    path: '/:userToken/:analysisScore/:percentageSleep',
    name: 'analysis',
    component: Analysis
  },
  {
    path: '/Login',
    name: 'loginpage',
    component: LoginPage
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
