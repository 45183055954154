<template>
    <div class="login-wrapper">
      <div class="login-card">
        <header class="login-header">
          <div ><v-img src="../assets/Avatar.png" max-height="200" alt="..."></v-img></div>
        </header>
        <h2>ล็อกอินเข้าใช้งาน</h2>
        <form @submit.prevent="login">
          <div class="input-group">
            <label for="username"></label>
            <input id="username" type="number" v-model="credentials.username" required placeholder="เบอร์โทรศัพท์">
          </div>
          <div class="input-group">
            <label for="password"></label>
            <input id="password" type="password" v-model="credentials.password" placeholder="รหัสผ่าน" required>
          </div>
          <br/>
          <button type="submit" class="login-btn">Login</button>
          <br/>  <br/>
          <router-link to="/"><v-btn variant="text" color="orange"><h3>กลับไปหน้าหลัก</h3></v-btn></router-link>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios'
  import { toast } from 'vue3-toastify';
  import 'vue3-toastify/dist/index.css';
  // import router from '../../src/router'
  import sha from 'sha.js';
  
  export default {
    
    data() {
      return {
        credentials: {
          username: null,
          password: '',
          dataForHash: null
        }
      };
    },
    methods: {
      setItemWithExpiry(key, value, ttl) {
      const now = new Date();

      // `item` ประกอบด้วยข้อมูลและเวลาที่ข้อมูลนั้นหมดอายุ
      const item = {
        value: value,
        expiry: now.getTime() + ttl,
        }
        localStorage.setItem(key, JSON.stringify(item));
      const userTimeout = {
        value: this.credentials.username,
        expiry: now.getTime() + ttl,
        }
        localStorage.setItem("UserID", JSON.stringify(userTimeout));
      },
      async hashData(password) {
        const timestamp = Date.now();
        // ใช้ sha.js เพื่อสร้าง hash ด้วย SHA-256
        const hash = sha('sha256').update(password+timestamp).digest('hex');
        this.$router.push({ name: 'questionnaire', params: { userToken: hash} });
        console.log(hash);
        return hash;
      },
      async login() {
        // Handle login
        // console.log(this.credentials);
        await axios.post('http://146.190.88.63:11540/api/3187dcd5e46382b7e46382b761d6b13f844efa', {
          phonenumber: this.credentials.username,
          password: this.credentials.password
        })
        .then( response => {
          console.log(response);
                this.users = response.data;
                if(response.status === 200){
                  this.setItemWithExpiry('userToken', this.users, 3600000);
                    toast.success('ลงชื่อเข้าใช้สำเร็จ', {
                      position: "top-center",
                      autoClose: 2000,
                      hideProgressBar: true,
                      closeOnClick: false,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "auto",
                    });
                        setTimeout(() => {
                          this.hashData(this.credentials.password);
                        }, 3000);
                }
                else{
                  console.log('fail')
                    toast.error("รหัสไม่ถูกต้อง!", {
                        autoClose: 10000,
                        type: "error",
                        closeOnClick: true,
                        hideProgressBar: true,
                        theme: "colored",
                    }); 
                }
        })
        .catch(error => {
          // จัดการกับ error ที่นี่
          console.log('fail')
                    toast.error("รหัสไม่ถูกต้อง!", {
                        autoClose: 10000,
                        type: "error",
                        closeOnClick: true,
                        hideProgressBar: true,
                        theme: "colored",
                    }); 
          console.log(error)
        });
      },
    },
  };
  </script>
  
  <style scoped>
  .login-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 85vh;
    /* background-color: #8ecae6; */

  }
  
  .login-card {
    padding: 2rem;
    border-radius: 30px;
    background-color: #023047;
    width: 360px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    text-align: center;
    color: white;
  }
  
  .input-group {
    transition: border-color 0.5s;
    margin-bottom: 0.8rem;
  }
  
  .input-group label {
    display: block;
    color: #8ecae6;
  }
  
  .input-group input {
    width: 100%;
    padding: 0.6rem;
    margin-top: 0.5rem;
    border-radius: 10px;
    border: none;
    background-color: #e1f2fa;
  }
  
  .forgot-password {
    display: block;
    margin-bottom: 1rem;
    color: #8ecae6;
  }
  
  .login-btn {
    width: 100%;
    padding: 0.8rem;
    border: none;
    border-radius: 20px;
    background-color: #61cafa;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .login-btn:hover {
    background-color: darken(#8ecae6, 10%);
  }
  
  /* Add additional styles and responsive design as needed */
  </style>
  